.loginMain {
  background-image: url(https://daqun-static.imeete.com/upload/2f/1_2fd75e5.jpeg) !important;
  background-position: center;
  background-size: 100% 100%;
  background-attachment:scroll;
  min-height: 1000px;
  ::after {
    position: absolute;
    content: '';
    filter: blur(0px);

  }
}