.editCateName, .createCate, .deleteCate {
  margin-left: 10px;
  z-index: 1000;
  :hover {
    color: rgb(52, 118, 218);
    transform: scale(1.2);
  }
}
.cateName {
  width: 100px;
  max-width: 180px;
  overflow: hidden;
  word-wrap: break-word;
  margin-right: 20px;
}