
@import 'antd/dist/antd.css';
.menuContent {
    background-image: linear-gradient(#F3F9FE, #F4FAFE, #FAFDFE, #FDFEFF, #FDFEFF);
    .ant-layout {
        padding-left: 30px;
        background-image: linear-gradient(#F3F9FE, #F4FAFE, #FAFDFE, #FDFEFF, #FDFEFF);
        // .ant-menu-submenu-title {
        //     padding-left: 30px;       
        //     .ant-menu-title-content {
        //         max-width: 120px;
        //         overflow: hidden;
        //         &::after {
        //             content: '+';
        //             width: 10px;
        //             height: 10px;
        //             border: 1px solid #aaa;
        //         }
        //     }
        // }
        .ant-layout-content {
            // position: fixed;
            // width: calc(100% - 319px);
            margin-left: 46px;
            margin-right: 10px;
        }

        .addCate, .addArticle {
            border: 1px solid #ccc;
            padding: 2px;
            margin-left: 15px;
        }
        .ant-menu-submenu {
            overflow-y: scroll;
            scrollbar-width: none; /* firefox */
            -ms-overflow-style: none; /* IE 10+ */
            overflow-x: hidden;
            overflow-y: auto;
        }
        .ant-menu-submenu::-webkit-scrollbar {
            display: none; /* Chrome Safari */
        }
        .content-title {
            min-width: 200px;
            margin-left: 30px;
            margin-bottom: 30px;
            span {
                width: inherit;
                margin-left: 20px;
            }
        }
    }
}