.editContent {
    .w-md-editor {
      // height: 700px !important;
      min-height: 700px;
      .w-md-editor-content {
        // height: 671px !important;
        min-height: 671px;
      }
      .w-md-editor-text {
        background-color: #deebec;
      }
      .w-md-editor-text-pre {
        color: #ccc !important;
      }
    }
  }
  
  