
@import 'antd/dist/antd.css';
.editBtnWrap {
    float: right;
    position: sticky;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    button {
        float: right;
        border: 1px solid #ccc ;
        min-width: 80px;
        text-align: center;
        font-weight: 400px;
        margin: 10px;
    }
    .saveBtn, .deleteBtn {
        position: absolute;
        right: 1px;
        margin-top: 63px;
    }
    .saveBtn {
        margin-right: 100px;
    }
}